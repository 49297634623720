
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin} from '../../../mixins';
import { AthleteMetric, PerformanceCategory, getPerformanceCategoryValues, DevelopmentalAge } from '@/../types/enums';
import { AgeGroup, AgeGroupValues } from '@best-athletes/ba-types';
import { AthleteAssessmentDataModel } from '../../../models/athlete/AthleteAssessmentDataModel'
import CompareAgeGroupSelector from './CompareAgeGroupSelector.vue';
import AssessmentDataInfoProvider from '../../hoc/AssessmentDataInfoProvider.vue';
import Dialog from '../../ui/Dialog.vue';
import ProgressBar from '../../charts/ProgressBar.vue';
import ComparisonBarChartV2 from '../../charts/ComparisonBarChartV2.vue';
import PerformanceLevelPreview from './PerformanceLevelPreview.vue';
import { athleteProfile, comparativeDataStore } from '../../../store';
import { performanceCategoryToLabel } from '../../../pipes/enum.pipes';
import { getMetricColor } from '../../../pipes/metric-color.pipe';
import RoundedButton from "@/components/forms/RoundedButton.vue";
import ProductInfoProvider from "@/components/hoc/ProductInfoProvider.vue";
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";

@Component({
	components: {
		AssessmentDataInfoProvider,
		ComparisonBarChartV2,
		CompareAgeGroupSelector,
		Dialog,
		ProgressBar,
		PerformanceLevelPreview,
		RoundedButton,
		ProductInfoProvider,
		SubscriptionInfoProvider
	}
})
export default class AthleteComparativeDataPerformanceSummary extends Mixins(VuetifyMixin){
	getMetricColor = getMetricColor;
	@Prop({type: Boolean, default: false}) loading: boolean;
	@Prop() athleteId: string;
	@Prop() assessmentData: AthleteAssessmentDataModel;
	@Prop() metric: AthleteMetric;
	@Prop() metricPerformanceCategory: PerformanceCategory;

	showDevelopmentalAgeDialog: boolean = false;
	showPerformanceDialog: boolean = false;

	@Watch('assessmentData', { immediate: true })
	assessmentDataChanged(): void {
		this.updateGroups(this.CompareGroups)
	}

	get AvailableCompareGroups(): AgeGroup[]{
		return AgeGroupValues;
	}
	get CompareGroups(): AgeGroup[]{
		return this.FeatureAvailable ? comparativeDataStore.compareGroups : [AgeGroup.U13, AgeGroup.O20];
	}
	set CompareGroups(newGroups: AgeGroup[]){
		this.updateGroups(newGroups)
	} 

	get ComparativeDataLoading(): boolean{
		return comparativeDataStore.ComparativeDataLoading;
	}

	get DevelopmentalAge(): DevelopmentalAge | null{
		if(this.assessmentData){
			return this.assessmentData.developmentalCategory;
		}
		return null;
	}
	get MetricPerformanceCategory(): string | null{
		if(comparativeDataStore.ComparisonReady){
			return performanceCategoryToLabel(comparativeDataStore.Comparison[this.metric]);
		}
		return null;
	}

	get MyAgeGroup(): AgeGroup | null{
		return athleteProfile.AthleteAgeGroup;
	}
	get AthleteAgeGroupLabel(): string | null{
		return athleteProfile.AthleteAgeGroupLabel;
	}
	get AthleteAgeGroupIsUnderU13(): boolean{
		return athleteProfile.AthleteAgeGroupIsUnderU13;
	}

	get Over18(): boolean{
		return comparativeDataStore.Over18;
	}

	updateGroups(ageGroups: AgeGroup[]): void{
		comparativeDataStore.setComparativeGroups({
			assessmentId: this.assessmentData.id,
			ageGroups: ageGroups,
			athleteId: this.athleteId,
		});
	}

	get PerformanceCategories(): PerformanceCategory[]{
		return getPerformanceCategoryValues(this.Over18);
	}

	get FeatureAvailable(): boolean {
		return comparativeDataStore.featureAvailable;
	}
}
