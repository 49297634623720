
import * as Routes from '@/../types/constants/web_client_user.routes';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric, AthleteMetricValues } from '@/../types/enums';
import Page from './Page.vue';
import { AuthMixin, AthleteRoutingMixin, BAIconsMixin } from '@/mixins';
import { athleteProfile } from '@/store';
import { SelfAssessmentModel } from '../models/athlete/SelfAssessmentModel';
import { formatDateMonthDay } from '../helpers';
import { formatNumber } from '../pipes/formatNumber.pipe';
import AthleteSelfAssessmentsCard from '../components/profile/athlete/AthleteSelfAssessmentsCard.vue';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';

@Component({
	components:{
		Page,
		AthleteSelfAssessmentsCard,
	},
	async beforeRouteEnter(to, _, next){
		next((vm: AthleteSelfReportSummary) => {
			if(vm.athleteId !== null){
				athleteProfile.getAthleteProfile(vm.athleteId);
				athleteProfile.loadSelfAssessments({athleteId: vm.athleteId});
			}else{
				athleteProfile.getAthleteProfile(vm.$auth.athleteId);
				athleteProfile.loadSelfAssessments({athleteId: vm.$auth.athleteId});
			}
		});
	},
	async beforeRouteUpdate(_, __, next){
		if(this.athleteId !== null){
			athleteProfile.getAthleteProfile(this.athleteId);
			athleteProfile.loadSelfAssessments({athleteId: this.athleteId});
		}else{
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
			athleteProfile.loadSelfAssessments({athleteId: this.$auth.athleteId});
		}
		next();
	},
})
export default class AthleteSelfReportSummary extends Mixins(AuthMixin, AthleteRoutingMixin, BAIconsMixin){
	AthleteMetric = AthleteMetric;
	formatDateMonthDay = formatDateMonthDay;
	formatNumber = formatNumber;
	@Prop({ default: null }) athleteId: string | null;
	@Prop({ type: Boolean }) buttons;

	get Loading(): boolean{
		return athleteProfile.IsLoading || athleteProfile.selfAssessmentsLoading;
	}
	get AthleteProfile(): AthleteProfileModel{
		return athleteProfile.athleteProfile;
	}
	get CanContinueAssessment(): boolean{
		if(this.SelfAssessments.length === 0) return false;
		return this.FirstAssessment.IsStarted && !this.FirstAssessment.IsExpired && !this.FirstAssessment.IsComplete;
	}
	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get SelfAssessmentData(): SelfAssessmentModel | null{
		return athleteProfile.selfAssessmentSummary;
	}
	get FirstAssessment(): SelfAssessmentModel{
		if(this.SelfAssessments[0] === undefined) return new SelfAssessmentModel();
		return this.SelfAssessments[0];
	}
	get SelfAssessments(): SelfAssessmentModel[]{
		return athleteProfile.selfAssessments.slice().sort((a,b) => {
			return +b.startDate - +a.startDate;
		});
	}
	get SelfAssessmentTrend(): Record<AthleteMetric, { trend: number[], change: string, positive: boolean }>{
		return {
			[AthleteMetric.Acceleration]: this.trendValuesForMetric(AthleteMetric.Acceleration),
			[AthleteMetric.Speed]: this.trendValuesForMetric(AthleteMetric.Speed),
			[AthleteMetric.Power]: this.trendValuesForMetric(AthleteMetric.Power),
			[AthleteMetric.Agility]: this.trendValuesForMetric(AthleteMetric.Agility),
			[AthleteMetric.Recovery]: this.trendValuesForMetric(AthleteMetric.Recovery),
		}
	}
	trendValuesForMetric(metric: AthleteMetric): {
		trend: number[],
		change: string,
		positive: boolean,
	}{
		const metricKey = `${metric}Results`;
		const trend = this.SelfAssessments.slice().sort((a,b) => {
			return +a.startDate - +b.startDate;
		}).map(assesment => {
			return assesment[metricKey].finalResult
		}).filter(x => x !== null);
		let change: number, positive: boolean | null = null;
		const len = trend.length;
		if(len <= 1){
			change = 0;
			positive = null;
		}else{
			const last = trend[len-1];
			const prev = trend[len-2];
			change = last - prev;
			if(change > 0){
				positive = true;
			}else if (change < 0){
				positive = false;
			}else{
				positive = null;
			}
		}
		let changeString: string;
		let changeFixed: string = Math.abs(change).toFixed(2);
		switch(metric){
		case AthleteMetric.Acceleration:
		case AthleteMetric.Speed:
			positive = positive === null ? null : !positive; // Lower is better
			if(positive === true){
				changeString = `${changeFixed}s faster`;
			}else if(positive === false){
				changeString = `${changeFixed}s slower`;
			}
			break;
		case AthleteMetric.Power:
			if(positive === true){
				changeString = `${changeFixed}cm higher`;
			}else if(positive === false){
				changeString = `${changeFixed}cm lower`;
			}
			break;
		case AthleteMetric.Recovery:
			if(positive === true){
				changeString = `${changeFixed} higher`;
			}else if(positive === false){
				changeString = `${changeFixed} lower`;
			}
			break;
		case AthleteMetric.Agility:
			changeString = `${changeFixed} RSI`;
			break;
		}
		return {
			change: changeString,
			positive,
			trend,
		}
	}
	addMetric(metric: AthleteMetric){
		this.$router.push({
			name: Routes.AthleteSelfAssessmentForm,
			params:{
				metric,
			}
		});
	}
}
