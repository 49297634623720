
import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import AthleteComparativeData from '@/views/AthleteComparativeData.vue';
import AthleteComparativeDataV2 from '@/views/AthleteComparativeDataV2.vue';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { RawLocation } from 'vue-router';

@Component({
	components: {
		AthleteComparativeData,
		AthleteComparativeDataV2
	}
})
/**
 * Root view for the Athlete or Coach's TeamDashboard. Chooses which component to be rendered
 */
export default class AthleteComparativeDataView extends Vue {
	get Component(): VueConstructor {
		return AthleteComparativeData;
		return AthleteComparativeDataV2;
	}
	created() {
		let newLocation: RawLocation = {
			name: Routes.AthleteComparativeDataSummary,
			params: {
				metric: 'power'
			}
		}
		newLocation = {
			name: Routes.AthleteComparativeDataSummary,
			params: {
				metric: 'power'
			}
		};
		// V2
		// newLocation = {
		// 	name: Routes.AthleteComparativeDataCompare
		// };

		if (this.$route.name !== newLocation.name) {
			this.$router.replace(newLocation);
		}
	}
}
