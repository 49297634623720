
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiPencil } from '@mdi/js';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';


@Component
export default class TestGroupHeader extends Mixins(VuetifyMixin, BAIconsMixin){
	mdiPencil = mdiPencil;
	@Prop({ default: mdiPencil }) icon: string;
	@Prop({ default: false, type: Boolean }) isOpen: boolean;
	@Prop({ default: false, type: Boolean }) readOnly: boolean;
	@Prop({ default: false, type: Boolean }) disabled: boolean;
	@Prop({ default: false, type: Boolean }) useChevron: boolean;
	@Prop({ default: false, type: Boolean }) isValid: boolean;

	get RowComplete(): boolean{
		return this.isValid && !this.disabled;
	}
	get IconColor(): string{
		if(this.isOpen || this.RowComplete){
			return this.getColor('baColorPrimaryBlue');
		}
		return this.getColor('baColorGray1');
	}

	get HeaderColors(): Record<string, string>{
		if(this.isOpen){
			return {
				'color': this.getColor('baColorPrimaryText'),
				'font-weight': '500',
				'background-color': this.getColor('white'),
				'box-shadow': `0px 5px 5px rgba(0, 0, 0, 0.13)`,
			};
		}
		if(!this.RowComplete){
			return {
				'color': this.getColor('baColorSecondaryText'),
				'background-color': this.getColor('white'),
				'border': `1px solid ${this.getColor('baColorGray8')}`
			};
		}
		return {
			'color': this.getColor('baColorDeepBlue'),
			'background-color': this.getColor('white'),
			'border': `1px solid ${this.getColor('success')}`
		};
	}

	get HeaderStyle(): Record<string, string>{
		return {
			...this.HeaderColors,
		};
	}
	
}
