
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import * as Routes from '@/../types/constants/web_client_user.routes';
import Page from './Page.vue';
import Alert from '../components/ui/Alert.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import CodeInput from '../components/forms/CodeInput.vue';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import AssociateDiagram from '../components/ui/AssociateDiagram.vue';
import { MultiStepFormMixin } from '../mixins/MultiStepFormMixin';
import { AuthMixin, FormRulesMixin, DebounceMixin, BAIconsMixin } from '../mixins';
import { teamApi } from '../api/TeamApi';
import { TeamModel } from '../models/team';
import { athleteProfile, userStore, notificationStore } from '../store';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { UniqueTokenType, ActionType } from '@/../types/enums';
import { NotificationModel, NotificationAction } from '@/models/notification/NotificationModel';
import { ActionableModel } from '@/models';
import { navigateAction } from "@/helpers";

@Component({
	components: {
		Alert,
		AssociateDiagram,
		CodeInput,
		MultiStepForm,
		Page,
		SurveySvg,
	},
})
export default class TeamJoinForm extends Mixins(DebounceMixin, MultiStepFormMixin, FormRulesMixin, AuthMixin, BAIconsMixin){


	@Prop({ default: false, type: Boolean }) athleteMode?: boolean;

	resetForm(): void{
		this.formValue = this.defaultForm();
	}

	loading: boolean = false;
	get Loading(): boolean{
		return this.loading;
	}
	steps = 2;
	currentStep = 1;

	get TeamName(): string{
		if(!this.codeTeam) return "";
		return this.codeTeam.name;
	}
	get TeamLogoUrl(): string{
		if(!this.codeTeam) return "";
		return this.codeTeam.logoUrl;
	}
	get AthleteName(): string{
		if(!this.UserAthleteInfo) return "";
		return this.UserAthleteInfo.FullName;
	}

	get CurrentStepValid(): boolean {
		const step = this.formValue[this.CurrentStepKey];
		if(this.currentStep === 1){
			return step.valid && this.codeTeam !== null;
		}
		return step ? step.valid : true;
	}

	get Progress(): number{
		switch(this.currentStep){
		case 1: return 50;
		case 2: return 80;
		default: return 100;
		}
	}

	get UserAthleteInfo(): AthleteProfileModel{
		return userStore.athlete;
	}

	defaultForm = (): Record<string,any> => ({
		step1: {
			valid: false,
			joinCode: "",
		},
	});
	formValue = this.defaultForm();
	updateJoinCode(joinCode: string): void{
		this.formValue.step1.joinCode = joinCode.toUpperCase();
	}

	nextIfValid(){
		if(this.CurrentStepValid){
			this.next();
		}
	}

	codeValid: boolean = true;
	codeTeam: TeamModel | null = null;
	codeValidLoading: boolean = false;
	token: {id: string, name: string} | null = null;
	get JoinCodeErrorMessages(): string[]{
		if(this.codeValid || this.codeValidLoading === true){
			return [];
		}
		return ["Join code invalid"];
	}
	@Watch('formValue.step1.joinCode')
	private async validateCode(code: string){
		this.codeTeam = null;
		this.formError = "";
		if(code.length !== 9) return;
		this.codeValid = false;
		this.codeValidLoading = true;
		this.debounceCallback('validateCode', () => {
			teamApi.validateCode({ code }).then(({valid, team, token}: { valid: boolean, team: TeamModel | null, token: {id: string, name: string } | null }) => {
				this.codeValid = valid;
				this.codeTeam = team;
				this.token = token;
			}).catch(e => {
				console.error("Failed to validate team code", e);
				this.codeValid = false;
			}).finally(() => {
				this.codeValidLoading = false;
			})
		}, 2000);
	}

	formError: string = "";
	async finish(): Promise<void>{
		this.formError = "";
		try{
			await teamApi.joinTeamWithCode({
				token: {
					name: this.token.name as UniqueTokenType,
					id: this.formValue.step1.joinCode,
				},
				athleteId: this.UserAthleteInfo.id,
				teamId: this.codeTeam.id,
			});
			// Reload teams in SideNav
			await athleteProfile.getAthleteProfile(this.UserAthleteInfo.id);
			await userStore.refreshToken(true);

			// Push notification and go to team page
			const navigationAction = navigateAction(Routes.AthleteTeamDashboard, {
				teamId: this.codeTeam.id,
				athleteMode: true,
				athleteId: this.UserAthleteInfo.id
			})

			// View Team action
			const viewTeamAction:NotificationAction = {
				buttonText: 'View Team',
				buttonColor: 'primary',
				action: navigationAction,
			}

			await notificationStore.pushLocalNotification(new NotificationModel(`You are now a member of ${this.TeamName}!`, [viewTeamAction]));
			this.goToDashboard();
		}catch(e){
			console.error("Failed to Join team", e);
			this.formError = "Failed to join team, please double check your Team Code and try again later.";
		}
	}
	cancel(): void{
		this.goToDashboard();
	}

	goToDashboard(): void{
		if(this.athleteMode){
			this.$router.push({
				name: Routes.AthleteDashboard
			});
		}else{
			this.$router.push({ name: Routes.CoachDashboard });
		}
	}
}
