
import { Component, Mixins } from 'vue-property-decorator';
import { AthleteMetric } from '@/../types/enums';
import { AthleteMetricsMixin, VuetifyMixin } from '../../../mixins';
import { title, toFixed } from '../../../pipes';
import AttemptField from '../utility/AttemptField.vue';
import Stopwatch from '../utility/Stopwatch.vue';
import { SelfAssessmentTestAttemptModel, SelfAssessmentTestModel } from '../../../models/athlete/SelfAssessmentTestModel';

@Component({
	components: { AttemptField, Stopwatch },
})
export default class SelfAssessmentAgilityTest extends Mixins(AthleteMetricsMixin, VuetifyMixin){
	title = title;
	public readonly Title: string = this.title(this.metric);
	get metric(): AthleteMetric{
		return AthleteMetric.Agility;
	}

	manualEntry: boolean = false;
	get ManualEntryAttrs(): Record<string, any>{
		if(this.manualEntry === false) return {};
		return {
			readonly: false,
			type: 'number',
			step: '0.1'
		};
	}

	get StopWatchDisabled(): boolean{
		return this.manualEntry || this.TestValid;
	}
	get TestValid(): boolean{
		return this.attempts.length > 1;
	}
	attempts: SelfAssessmentTestAttemptModel[] = [];
	
	stopwatchDuration: number = 0;
	stopwatchTimeFmt: string = "00:00.000";
	addAttempt(): void{
		this.attempts.push(new SelfAssessmentTestAttemptModel().load({
			result: this.stopwatchDuration / 1000,
			label: this.stopwatchTimeFmt,
		}));
	}
	trashAttempt(index: number){
		this.attempts.splice(index, 1);
	}

	finish(){
		const [ finalResult ] = this.attempts.slice().sort((a,b) => a.result - b.result);
		const computedResult = finalResult.result;
		this.$emit('finish', new SelfAssessmentTestModel(this.metric).load({
			finalResult: toFixed(computedResult),
			finalResultLabel: toFixed(computedResult),
			attempts: this.attempts
		}));
	}
}
