import { render, staticRenderFns } from "./EditRecruitingProfileVideos.vue?vue&type=template&id=586c6b31&scoped=true&"
import script from "./EditRecruitingProfileVideos.vue?vue&type=script&lang=ts&"
export * from "./EditRecruitingProfileVideos.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586c6b31",
  null
  
)

export default component.exports