
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, MyAthleteMixin, AthleteRoutingMixin, BAIconsMixin } from "@/mixins";
import SchoolDetailProvider from "@/components/hoc/SchoolDetailProvider.vue";
import SpecificSchool from "../components/search/school/SpecificSchool.vue";
import * as Routes from "@/../types/constants/web_client_user.routes";
import { Route } from "vue-router";
import FavouriteListProvider from "@/components/hoc/FavouriteListProvider.vue";
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";
import { notificationStore, schoolStore } from "@/store";
import SchoolSearchSvg from "@/components/svg/SchoolSearchSvg.vue";
import FeatureNotAvailableDialog from "@/components/subscriptions/FeatureNotAvailableDialog.vue";
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import { StartCheckoutParams } from '@/../types/interfaces';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import { CustomerApi } from '@/api/CustomerApi';
import SubscriptionsDialog from "@/components/subscriptions/SubscriptionsDialog.vue";
import { DummySchool } from '@/models/school/schoolDummyModel';
import { SchoolFavouriteItem } from '@/models/favouriteList/SchoolFavoriteListModel';

@Component({
	components: {
		SpecificSchool,
		SchoolDetailProvider,
		FavouriteListProvider,
		SubscriptionInfoProvider,
		SchoolSearchSvg,
		FeatureNotAvailableDialog,
		SubscriptionsDialog
	}
})
export default class SchoolDetailPage extends Mixins(VuetifyMixin, MyAthleteMixin, AthleteRoutingMixin, BAIconsMixin) {

	showPlansModal = false;
	getPlanLoading = false;

	DummySchool = DummySchool;
	SchoolFavouriteItem = SchoolFavouriteItem;

	@Prop({ type: String, default: null }) schoolId: string | null;
	@Prop({ type: Number, default: null }) locationId: number | null;

	get AthleteSport(): string{
		return this.TheAthleteProfile.Sport.name
	}
	get Gender(): string{
		return this.TheAthleteProfile.gender
	}	
	get AthleteSchoolRoute(): Partial<Route> {
		return {
			name: Routes.AthleteSchoolSearch,
			params: {
				...this.$route.params,
				athleteId: this.TheAthleteId
			}
		};
	}

	get Loading(): boolean{
		return schoolStore.schoolsLoading || this.IsAthleteLoading;
	}

	get LocationId(): number {
		return this.locationId !== null && this.locationId !== undefined ? this.locationId : 0;
	}

	async getPlan(plan: SubscriptionPlanModel): Promise<void>{
		this.showPlansModal = false;
		this.getPlanLoading = true;
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title: `Upgrade to ${plan.title} Plan`,
			parentId: this.TheAthleteId,
			products:[
				{
					productId: plan.stripeProductId,
					quantity: 1,
					meta: {
						athleteId: this.TheAthleteId,
					},
				}
			],
			allowCoupons: true,
			automaticTax: true,
		};
		try{
			const intent = await CustomerApi.createCheckoutIntent('athlete', this.TheAthleteId, params);
			this.$router.push({
				name: Routes.AthleteCheckoutPage,
				params:{
					...this.$route.params,
					checkoutIntentId: intent.id
				}
			});
		}catch(e){
			console.error("Failed to purchase plan", e);
			notificationStore.pushErrorNotificationMessage("Something went wrong, please try again later");
		}finally{
			this.getPlanLoading = false;
		}
	}
}
