
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin , BAIconsMixin} from '@/mixins';
import SharingMetric from "@/components/sharing/SharingMetric.vue";
import ShareProfile from "@/components/profile/recruiting/ShareProfile.vue";
import { DataTableHeader } from 'vuetify';
import { DataTableSortMixin } from '@/mixins';
import { SharingHistoryRecord } from '@/../types/interfaces';
import AthleteTargetedSharingModule from '@/store/targetedSharing/AthleteTargetedSharing.store';
import { getModule } from 'vuex-module-decorators';

@Component({
	components: {
		SharingMetric,
		ShareProfile,
	}
})
export default class SharingHistory extends Mixins(DataTableSortMixin, VuetifyMixin, StringsMixin, BAIconsMixin){
	@Prop({ type: Number, required: true }) value: number;
	@Prop({ type: String, required: true }) label: string;
	@Prop({ default: [] }) records: SharingHistoryRecord[];

	search = '';
	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
		},
		{
			text: 'Email',
			value: 'email',
		},
		{
			text: 'Date',
			value: 'date',
		},
		{
			text: '',
			value: 'actions',

		}
	];

	@Watch('showShareModal')
	onShowShareModal() {
		if( !this.showShareModal ) {
			this.shareEmails = [];
		}
	}

	showShareModal: boolean = false;
	submitShareProfile(payload: { emails: string[], message: string }): void{
		this.$emit('share', payload);
	}

	shareEmails: string[] = [];
	onReshare(record: SharingHistoryRecord) {
		this.shareEmails = [record.email];
		this.showShareModal = true;
	}

	showArchive = false;
	get Records(): SharingHistoryRecord[] {
		return this.showArchive? this.Archive : this.records.filter(r => !r.archive);
	}
	get HasArchive(): boolean {
		return !this.records.every(r => !r.archive);
	}
	get Archive(): SharingHistoryRecord[] {
		return this.records.filter(r => r.archive);
	}
	async onToggleArchive( record: SharingHistoryRecord ) {
		record.archive = !record.archive;
		const athleteTargetedSharingStore = getModule(AthleteTargetedSharingModule);
		await athleteTargetedSharingStore.patchRecord(record);

		if( !this.HasArchive ) this.showArchive = false;
		this.$emit('refresh', {id: record.id});
	}

}
