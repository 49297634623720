
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AthleteRoutingMixin, AuthMixin, BAIconsMixin } from "@/mixins";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import ViewScoutingReport from '../views/ViewScoutingReport.vue';

@Component({
	components: {ViewScoutingReport },
})

export default class AthleteScoutingReport extends Mixins(AuthMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin, AthleteRoutingMixin, BAIconsMixin){
	@Prop({ default: null, type: String }) reportId: string | null;

}

