import { render, staticRenderFns } from "./SharingHistory.vue?vue&type=template&id=267c2374&scoped=true&"
import script from "./SharingHistory.vue?vue&type=script&lang=ts&"
export * from "./SharingHistory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "267c2374",
  null
  
)

export default component.exports