
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, MyAthleteMixin, BAIconsMixin } from '@/mixins';
import SharingMetric from "@/components/sharing/SharingMetric.vue";
import ShareProfile from "@/components/profile/recruiting/ShareProfile.vue";
import { DataTableHeader } from 'vuetify';
import { DataTableSortMixin } from '@/mixins';
import ProfilePictureProvider from '@/components/hoc/ProfilePictureProvider.vue';
import { ViewTrackingRecordViewModel } from '@/models/viewTrackingRecord/viewTrackingRecordViewModel';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import { athleteApi } from '@/api/AthleteApi';

@Component({
	components: {
		SharingMetric,
		ShareProfile,
		SubscriptionInfoProvider,
		ProfilePictureProvider,
	}
})
export default class SharingViewHistory extends Mixins(DataTableSortMixin, VuetifyMixin, StringsMixin, MyAthleteMixin, BAIconsMixin) {
	@Prop({ type: Number, required: true }) value: number;
	@Prop({ type: String, required: true }) label: string;
	@Prop({ default: [] }) records: ViewTrackingRecordViewModel[];

	search = '';
	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'Name',
			sortable: false,
		},
		{
			text: 'Email',
			value: 'viewer.email',
			sortable: false,
		},
		{
			text: 'Date',
			value: 'date',
			sortable: true,
		},
		{
			text: '',
			value: 'actions',
			sortable: false,
		},
	];

	showShareModal: boolean = false;
	submitShareProfile(payload: { emails: string[], message: string }): void{
		this.$emit('share', payload);
	}

	showArchive = false;
	get Records(): ViewTrackingRecordViewModel[] {
		return this.showArchive? this.Archive : this.records.filter(r => !r.archive);
	}
	get HasArchive(): boolean {
		return !this.records.every(r => !r.archive);
	}
	get Archive(): ViewTrackingRecordViewModel[] {
		return this.records.filter(r => r.archive);
	}
	async onToggleArchive( record: ViewTrackingRecordViewModel ) {
		record.archive = !record.archive;
		await athleteApi.updateViewRecord(record);

		if( !this.HasArchive ) this.showArchive = false;
	}
}
