
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin, BAIconsMixin } from "@/mixins";
import IdCrossSvg from "@/components/svg/IdCrossSvg.vue";
import { userStore } from '@/store';
import * as Routes from '@/../types/constants/web_client_user.routes';

@Component({
	components: {
		IdCrossSvg
	}
})
export default class FeatureNotAvailableDialog extends Mixins(BAIconsMixin, VuetifyMixin) {
	@Prop({default: "Feature Not Available"}) text: string;

	AthleteProfileRoute(): void{
		this.$router.push({
			name: Routes.AthleteDashboard,
			params:{
				profileShortId: userStore.athlete.shortId,
				athleteId: userStore.athlete.id,
			}
		});
	}
}
