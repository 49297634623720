
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { SelfAssessmentTestAttemptModel } from '../../../models/athlete/SelfAssessmentTestModel';
import {BAIconsMixin, VuetifyMixin} from '@/mixins';

@Component
export default class AttemptField extends Mixins(BAIconsMixin, VuetifyMixin){
	@Prop() attempt: SelfAssessmentTestAttemptModel;
	private updateAttempt(attempt: SelfAssessmentTestAttemptModel){
		this.attempt = attempt;
		this.$emit('update:attempt', attempt);
	}
	@Prop({ default: false, type: Boolean }) hideClose: boolean;
	@Prop({ default: true, type: Boolean }) readonly: boolean;
	@Prop({ default: false, type: Boolean }) manual: boolean;
	@Prop({ default: 'text' }) type: string;
	@Prop({ default: null }) value: string | number;
	private input(value: string | number){
		this.$emit('input', value);
		this.updateAttempt(new SelfAssessmentTestAttemptModel().load({
			...this.attempt,
			label: `${value}`,
			result: value,
		}))
	}

	get Type(): string{
		if(this.type === 'number' && this.ValueIsNaN){
			return 'text';
		}
		return this.type;
	}

	get Value(): string | number{
		if(this.value !== null) return this.value;
		if(this.attempt === undefined) return '';
		if(this.attempt.label) return this.attempt.label;
		if(this.attempt.result) return `${this.attempt.result}`;
		return '';
	}
	get ValueIsNaN(): boolean{
		return Number.isNaN(+this.Value);
	}

	get ShowClose(): boolean{
		if(this.attempt === undefined) return false;
		if(this.hideClose) return false;
		return this.attempt.result !== undefined;
	}
}
