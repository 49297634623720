
import { Component, Mixins } from 'vue-property-decorator';
import { AthleteMetric } from '@/../types/enums';
import { AthleteMetricsMixin, VuetifyMixin } from '../../../mixins';
import { title, toFixed } from '../../../pipes';
import AttemptField from '../utility/AttemptField.vue';
import Stopwatch from '../utility/Stopwatch.vue';
import { SelfAssessmentTestAttemptModel, SelfAssessmentTestModel } from '../../../models/athlete/SelfAssessmentTestModel';

@Component({
	components: { AttemptField, Stopwatch },
})
export default class SelfAssessmentSpeedTest extends Mixins(AthleteMetricsMixin, VuetifyMixin){
	title = title;
	public readonly Title: string = this.title(this.metric);
	get metric(): AthleteMetric{
		return AthleteMetric.Speed;
	}

	formValid: boolean = false;
	rulesMinimum = (index: number): Array<() => boolean | string> => [
		() => this.getAttempt35MResult(index) >= this.getMinimumSplit(index) || 'Must be greater than 20M time'
	]

	manualEntry: boolean = false;
	get ManualEntryAttrs(): Record<string, any>{
		if(this.manualEntry === false) return {};
		return {
			readonly: false,
			type: 'number',
			step: '0.1'
		};
	}
	getAttempt35MResult(index: number): number{
		const attempt35M = this.attempts35M[index];
		if(attempt35M === undefined) return 0;
		return attempt35M.result;
	}
	getMinimumSplit(index: number): number{
		const attempt20M = this.attempts20M[index];
		if(attempt20M === undefined) return 0;
		return attempt20M.result;
	}
	
	get StopWatchDisabled(): boolean{
		return this.manualEntry || this.TestValid;
	}
	get TestValid(): boolean{
		return this.formValid && (this.attempts20M.length >= 1 && this.attempts35M.length >= 1) && (this.attempts20M.length === this.attempts35M.length);
	}
	attempts20M: SelfAssessmentTestAttemptModel[] = [];
	attempts35M: SelfAssessmentTestAttemptModel[] = [];
	
	stopwatchDuration: number = 0;
	stopwatchTimeFmt: string = "00:00.000";
	get Has20MAttempt(): boolean{
		if(this.attempts20M.length === 0) return false;
		if(this.attempts20M.length > this.attempts35M.length) return true;
		return false;
	}
	addAttempt20M(): void{
		this.attempts20M.push(new SelfAssessmentTestAttemptModel().load({
			result: this.stopwatchDuration / 1000,
			label: this.stopwatchTimeFmt,
		}));
	}
	addAttempt35M(): void{
		this.attempts35M.push(new SelfAssessmentTestAttemptModel().load({
			result: this.stopwatchDuration / 1000,
			label: this.stopwatchTimeFmt,
		}));
	}
	trashAttempt(index: number){
		this.attempts20M.splice(index, 1);
		this.attempts35M.splice(index, 1);
	}

	private compute10MTime(a25m: SelfAssessmentTestAttemptModel, a35m: SelfAssessmentTestAttemptModel): number{
		if(this.manualEntry){
			return toFixed(a35m.result - a25m.result, 2);
		}
		const seconds = ((a35m.result - a25m.result));
		return (~~(seconds * 100)) / 100;
	}

	/**
	 * REVIEW: Is this being calculated correctly?
	 */
	finish(){
		const computedAttempts = this.attempts20M.map((attempt, index) => {
			const velocity = this.compute10MTime(attempt, this.attempts35M[index]);
			return new SelfAssessmentTestAttemptModel().load({
				...attempt,
				result: velocity,
				label: velocity,
			});
		});
		const [ finalResult ] = computedAttempts.sort((a,b) => a.result - b.result);
		this.$emit('finish', new SelfAssessmentTestModel(this.metric).load({
			finalResult: finalResult.result,
			finalResultLabel: finalResult.label,
			attempts: computedAttempts,
		}));
	}
}
