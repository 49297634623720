
import { AnalyticsAssessmentFilter } from '@best-athletes/ba-types';
import { BaseSoccerPosition, BaseSoccerPositionValues } from '@/../types/enums';
import { AgeGroup, AgeGroupValues } from '@best-athletes/ba-types';
import { getEnumValues } from '@/../types/enums/get-enum-values';
import { getCategoryText } from '@/pipes/get-category-text.pipe';
import RadarChartComparative from "@/components/charts/RadarChartComparative.vue";
import ComparativeDataProvider, { CompareAgainstType, CompareAgainstValue } from '@/components/hoc/ComparativeDataProvider.vue';
import Alert from "@/components/ui/Alert.vue";
import { CurrentAthleteMixin, DebounceMixin, MyTeamsMixin, VuetifyMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import { RegisterForAssessmentMixin } from '@/mixins/RegisterForAssessmentMixin';
import { TeamModel } from '@/models/team';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';


export enum DimensionCategory {
	AGE_GROUP='Age Group',
	POSITION='Position',
	TEAM='Team',
}
export interface GenericDimension<C,V> {
	readonly?: boolean,
	category: C,
	value: V
}
export type Dimension = GenericDimension<DimensionCategory.AGE_GROUP, AgeGroup> | 
						GenericDimension<DimensionCategory.POSITION, BaseSoccerPosition> | 
						GenericDimension<DimensionCategory.TEAM, TeamModel>

export type DimensionOrNull = Dimension | null;

@Component({
	components: {
		Alert,
		ComparativeDataProvider,
		RadarChartComparative
	}
})
export default class AthleteComparativeDataCompare extends Mixins(
	VuetifyMixin,
	StringsMixin,
	CurrentAthleteMixin,
	MyTeamsMixin,
	DebounceMixin,
	RegisterForAssessmentMixin,
	BAIconsMixin
) {
	CompareAgainstType = CompareAgainstType;
	mdiCloudQuestion = "M 38.7 20.06 C 37.34 13.18 31.28 8 24 8 C 18.22 8 13.2 11.28 10.7 16.06 C 4.68 16.72 0 21.8 0 28 A 12 12 90 0 0 12 40 H 38 A 10 10 90 0 0 48 30 C 48 24.72 43.9 20.44 38.7 20.06 M 26 34 H 22 V 30 H 26 V 34 M 29.6 23.64 C 29 24.42 28.26 25 27.34 25.5 C 26.82 25.82 26.48 26.14 26.3 26.52 C 26.12 26.9 26 27.38 26 28 H 22 C 22 26.9 22.22 26.16 22.6 25.64 C 23 25.12 23.7 24.5 24.74 23.82 C 25.26 23.5 25.68 23.12 26 22.64 C 26.3 22.18 26.46 21.62 26.46 21 C 26.46 20.36 26.28 19.88 25.92 19.52 C 25.56 19.12 25 18.94 24.4 18.94 C 23.86 18.94 23.42 19.1 23 19.4 C 22.7 19.7 22.5 20.16 22.5 20.78 H 18.56 C 18.46 19.28 19 18 20.12 17.18 C 21.2 16.4 22.62 16 24.4 16 C 26.28 16 27.78 16.46 28.86 17.36 C 29.94 18.26 30.48 19.5 30.48 21 C 30.48 22 30.18 22.82 29.6 23.64 Z";


	@Prop({type: Boolean, default: false}) useSampleData: boolean;

	customDimensions: DimensionOrNull[] = [null, null];

	compareAgainst: CompareAgainstValue = null;
	getCategoryText = getCategoryText;

	hideFilters = false;

	created(): void {
		this.compareAgainst = {
			text: 'My Age',
			value: this.CurrentAthleteProfile.AgeGroup,
			type: CompareAgainstType.AGE_GROUP,
		};
	}

	async customDimensionAdd(index: number): Promise<void> {
		const dimensions = this.getDimensionCategories(index);
		const [nextAvailableDimension] = dimensions;
		if(nextAvailableDimension){
			this.$set(this.customDimensions,index, {
				category: nextAvailableDimension,
				value: null,
			})
		}
	}
	async customDimensionRemove(index: number): Promise<void> {
		this.$set(this.customDimensions, index, null);
	}
	async customDimensionCategoryChange(index: number, newCategory: DimensionCategory): Promise<void> {
		this.$set(this.customDimensions, index, {
			category: newCategory,
			value: null
		});
	}

	/**
	 * Team comparison feature flag is enabled and the athlete has teams to compare to.
	 */
	get UseTeamComparisons(): boolean{
		return this.MyAthleteTeams.length > 0;
	}

	get CategoryOptions() {
		const options =  {
			[DimensionCategory.AGE_GROUP]: AgeGroupValues.map(ageGroup => ({ text: ageGroup, value: ageGroup.toString()})),
			[DimensionCategory.POSITION]: BaseSoccerPositionValues	
		}
		if (this.UseTeamComparisons) {
			options[DimensionCategory.TEAM] =  this.MyAthleteTeams.map(team => ({ text: team.name, value: team}))
		}

		return options;
	}

	/**
	 * Returns list of filters returned by the server that we aren't able to change.
	 */
	getFixedFilters(serverFilters: AnalyticsAssessmentFilter[], state: ComparativeDataPageState): AnalyticsAssessmentFilter[]{
		if(!state.IsReady && !state.IsNotEnoughData) return [];
		if(this.compareAgainst.type === this.CompareAgainstType.CUSTOM){
			return serverFilters.filter(f => !this.ComparisonFiltersCategories.includes(f.category));
		}
		return serverFilters;
	}
	get ComparisonFiltersCategories(): AnalyticsAssessmentFilter['category'][]{
		return this.ComparisonFilters.map(f => f.category);
	}
	get ComparisonFilters(): AnalyticsAssessmentFilter[]{
		const filters: AnalyticsAssessmentFilter[] = []
		switch (this.compareAgainst.type) {
		case CompareAgainstType.TEAM:
			filters.push({
				"category": "team", 
				"value": this.compareAgainst.value.id,
				"operator": "="
			});
			break;
		case CompareAgainstType.POSITION:
			filters.push({
				"category": "position", 
				"value": this.compareAgainst.value as any,
				"operator": "="
			});
			break;
		case CompareAgainstType.AGE_GROUP:
			filters.push({
				"category": "age_group", 
				"value": this.compareAgainst.value,
				"operator": "="
			});
			break;
		case CompareAgainstType.CUSTOM:
			for (const dimension of this.customDimensions) {
				if (dimension !== null && dimension.value) {
					if (dimension.category === DimensionCategory.AGE_GROUP) {
						filters.push({
							"category": "age_group", 
							"value": dimension.value,
							"operator": "="
						})
					}
					else if (dimension.category === DimensionCategory.POSITION) {
						filters.push({
							"category": "position", 
							"value": dimension.value,
							"operator": "="
						})
					}
					else if (dimension.category === DimensionCategory.TEAM) {
						filters.push({
							"category": "team", 
							"value": dimension.value.id,
							"operator": "="
						})
					}
				}
			}
			break;
		default:
			break;
		}
		return filters;
	}

	get DimensionCategories(): DimensionCategory[] {
		const categories = getEnumValues(DimensionCategory);
		if(!this.UseTeamComparisons){
			categories.splice(categories.findIndex(c => c === DimensionCategory.TEAM), 1);
		}
		return categories;
	}
	/**
	 * Returns dimension categories available to a particular selector. This filters out categories used by other selectors.
	 */
	getDimensionCategories(index: number): DimensionCategory[] {
		const availableCategories = this.customDimensions.filter(x => !!x).map(d => d.category);
		const indexCategory = this.customDimensions[index] ? this.customDimensions[index].category : null;
		return this.DimensionCategories.filter(x => !!x).filter(x => (!availableCategories.includes(x) || x === indexCategory));
	}
}
