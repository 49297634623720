
import { Component, Mixins } from 'vue-property-decorator';
import { CurrentAthleteMixin } from '@/mixins';
import { DataOptions, DataTableHeader } from 'vuetify';
import { TeamModel } from '@/models/team';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { OrganizationType } from "@/../types/enums";
import { ProOrganizationDetails } from "@/../types/constants/web_client_user.routes";
import { adminStore } from '@/store';
import { orgAdminStore } from '@/store';
import { QueryOptions, RepositoryQuery } from '@/../types/interfaces';

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10000,		// load all organizations at once. if this is too slow, add paging mechanism
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};

class ProOrganizations{
	id: string;
	name: string;
	bio: string;
	logoUrl?: string;
	league: string;
	location: string;
	teams: TeamModel[];
}

@Component({
	components: {}
})
export default class ProOrganizationSearch extends Mixins(CurrentAthleteMixin){		

	get Loading():boolean{
		return false;
	}

	onClickOrg(item, event) {
		event.expand(!event.isExpanded);
	}

	onClickTeam(item, event) {
		this.gotoTeamDetails(item.id, item.org);
	}

	gotoTeamDetails(teamId: string, orgId: string) {
		this.$router.push({
			name: ProOrganizationDetails,
			params: {
				athleteId: this.CurrentAthleteId,
				teamId: teamId,
				orgId: orgId
			}
		});
	}

	expanded: []

	teamHeaders: DataTableHeader<any>[] = [
		{
			text: 'Team Name',
			sortable: false,
			value: 'name'
		},
		{
			text: 'Gender',
			sortable: true,
			value: 'gender'
		},
		{
			text: 'Division',
			sortable: true,
			value: 'division'
		},
		{
			text: '',
			sortable: false,
			value: 'details'
		}
	];

	orgHeaders: DataTableHeader<any>[] = [
		{
			text: '',
			sortable: false,
			value: 'fav'
		},
		{
			text: 'Club Name',
			sortable: true,
			value: 'name'
		},
		{
			text: 'League',
			sortable: true,
			value: 'league'
		},
		{
			text: 'Location',
			sortable: true,
			value: 'location'
		},
		{
			text: '', 
			value: 'data-table-expand' 
		},
	];

	get PageLoading(): boolean{
		return !this.OrganizationListReady;
	}
	get TableLoading(): boolean{
		return adminStore.organizationsLoading;
	}
	get OrganizationListReady(): boolean{
		return adminStore.organizationsInitialized && !adminStore.organizationsLoading;
	}
	get TotalPages(): number{
		return Math.ceil(adminStore.totalOrganizations / this.tableOptions.itemsPerPage);
	}

	mounted(): void{
		this.debounceUpdateTable();
	}

	tableUpdatePending: boolean = false;
	async debounceUpdateTable(): Promise<void> {
		this.tableUpdatePending = true;
		await this.loadOrganizations().then(() => {
			this.tableUpdatePending = false;
		})
	}

	search: string = "";
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	organizationsLoading: boolean = false;
	async loadOrganizations(): Promise<void>{
		this.organizationsLoading = true;
		try{
			const query: RepositoryQuery<OrganizationModel> = {
				search: this.search,
				fields: ['name'],
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await adminStore.loadOrganizationList({ query, options });
			await this.getProOrganizations();
		}catch(e){
			console.error(e);
		}finally{
			this.organizationsLoading = false;
		}
	}

	get Organizations(): OrganizationModel[] {
		const orgs = adminStore.organizationList;
		return orgs.filter((org) => {
			return org.type === OrganizationType.Professional;
		})
	}

	proOrgs: ProOrganizations[] = []
	async getProOrganizations(): Promise<ProOrganizations[]>{
		this.proOrgs = await Promise.all(this.Organizations.map( async org => {
			return {
				id: org.id,
				name: org.name,
				bio: org.FullBio,
				logoUrl: org.logoUrl,
				league: org.league,
				location: org.Location,
				teams: await this.OrganizationTeams(org.id)
			}
		}));
		return this.proOrgs;
	}
	get ProOrganizations(): ProOrganizations[] {
		return this.proOrgs;
	}

	get OrganizationTeamsLoaded(): boolean{
		return orgAdminStore.loadOrganizationTeamsInitialized && !orgAdminStore.loadOrganizationTeamsLoading;
	}
	get OrganizationTeamsLoading(): boolean{
		return orgAdminStore.loadOrganizationTeamsLoading;
	}
	get OrganizationTeamsLoadingError(): string{
		return orgAdminStore.loadOrganizationTeamsError;
	}

	async OrganizationTeams(orgId: string): Promise<TeamModel[]> {
		await orgAdminStore.loadOrganizationTeams({organizationId: orgId});
		return orgAdminStore.teamList;
	}
}
