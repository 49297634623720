
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin, SocialMediaMixin, StringsMixin, AthleteRoutingMixin, HelpInfoMixin } from '@/mixins';
import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { SelfAssessmentModel } from '@/models';
import convert from "convert-units";
import { isNotEmpty, isZero, toFixed } from '@/pipes';
import { notificationStore } from '@/store';

@Component({})
export default class StatsSummaryCard extends Mixins(VuetifyMixin, BAIconsMixin, SocialMediaMixin, StringsMixin, AthleteRoutingMixin, HelpInfoMixin) {
	@Prop({ required: true }) athleteProfile: AthleteProfileModel;
	@Prop({ required: true }) private assessmentData: AthleteAssessmentDataModel;
	@Prop({ default: null }) private selfAssessment: SelfAssessmentModel | null;

	get GradYear(): string {
		if( isZero(this.athleteProfile.GradYear) ) return undefined;
		return `${this.athleteProfile.GradYear}`;
	}
	get Height(): string {
		let height: number = undefined;

		// Height is always stored in metric(cm)
		if(this.athleteProfile.height)
			height = this.athleteProfile.height
		else if(this.assessmentData)
			height = this.assessmentData.standingHeight;
		else if(this.selfAssessment !== null)
			height = this.selfAssessment.standingHeightCm;

		if( isZero(height) || height < 0 ) return undefined;

		// Height in feet and inches
		const height_ft = Math.floor(convert(height).from(this.athleteProfile.heightUnit).to('ft'));
		const height_in =  Math.floor(convert(height).from(this.athleteProfile.heightUnit).to('in') % 12);

		return `${height_ft}'${height_in}” / ${height.toFixed(0)} cm`
	}
	get Weight(): string {
		let weight: number = undefined;

		// Height will always be in metric(kg)
		if(this.athleteProfile.weight)
			weight = this.athleteProfile.weight
		else if(this.assessmentData)
			weight = this.assessmentData.mass;
		else if(this.selfAssessment !== null)
			weight = this.selfAssessment.bodyMassKg;

		if( isZero(weight) || weight < 0 ) return undefined;

		const weight_lb = Math.ceil(convert(weight).from('kg').to('lb'));
		return `${weight_lb} lbs / ${toFixed(weight)} kg`
	}
	get DominantSide(): string {
		if( this.athleteProfile?.dominantSide ) return this.athleteProfile.dominantSide;
		if( this.assessmentData?.dominantFoot ) return this.assessmentData.dominantFoot;
		return undefined;
	}
	get Position(): string {
		if( this.athleteProfile?.Position ) return this.athleteProfile.Position;
		if( this.assessmentData?.Position ) return this.assessmentData.Position;
		return undefined;
	}
	get isIncomplete(): boolean {
		return( !this.GradYear || !this.Height || !this.Weight );
	}
	get hasInformation(): boolean {
		return( isNotEmpty(this.GradYear) ||
				isNotEmpty(this.Height) ||
				isNotEmpty(this.Weight) ||
				isNotEmpty(this.DominantSide) ||
				isNotEmpty(this.Position)
		)
	}
}
