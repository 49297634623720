
import { Component, Mixins } from 'vue-property-decorator';
import FilterSchoolSearch from '../components/search/school/FilterSchoolSearch.vue';
import SchoolSearch from '../components/search/school/SchoolSearch.vue';
import { CurrentAthleteMixin, LocalForageMixin } from '@/mixins';
import { schoolStore } from '@/store';

@Component({
	components: {
		FilterSchoolSearch,
		SchoolSearch,
	}
})
export default class AthleteSchoolSearch extends Mixins(CurrentAthleteMixin, LocalForageMixin) {
	showPlansModal = false;
	getPlanLoading = false;

	localForagePersistFields: Array<string | [string, any]> = [
		['databaseVersion', 2],
	]

	get Loading():boolean{
		return schoolStore.schoolsLoading
	}

	databaseVersion = 2;
	onChangeDBversion(version: number) {
		this.databaseVersion = version;
		this.persistField(this.LocalForagePersistFieldKeys);
	}	
}
