
import * as Routes from '@/../types/constants/web_client_user.routes';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import MobileNav, { MobileNavItem } from '@/components/ui/MobileNav.vue';
import { Component, Mixins } from 'vue-property-decorator';
import TeamDetailsCardV2 from '../components/teams/TeamDetailsCardV2.vue';
import { CurrentAthleteMixin, VuetifyMixin, LocalForageMixin } from '../mixins';
import { RegisterForAssessmentMixin } from '@/mixins/RegisterForAssessmentMixin';
import Page from '../views/Page.vue';
import { AthleteMetricValues } from '@/../types/enums';
import { ComparativeSampleDataMixin } from '@/mixins/ComparativeSampleDataMixin';
import ManageSampleDataDialog from '@/components/profile/athlete/ManageSampleDataDialog.vue';
import ComparativeDataUsingSampleDataAlert from '@/components/profile/athlete/ComparativeDataUsingSampleDataAlert.vue';
import ComparativeDataNoComparisonAlert from '@/components/profile/athlete/ComparativeDataNoComparisonAlert.vue';

@Component({
	components: {
		Page,
		TeamDetailsCardV2,
		SubscriptionInfoProvider,
		MobileNav,
		ManageSampleDataDialog,
		ComparativeDataUsingSampleDataAlert,
		ComparativeDataNoComparisonAlert,
	}
})
export default class AthleteComparativeDataV2 extends Mixins(
	ComparativeSampleDataMixin,
	CurrentAthleteMixin,
	VuetifyMixin,
	LocalForageMixin,
	RegisterForAssessmentMixin
) {


	get DashboardNavItemClasses(): Record<string, boolean> {
		return {
			'dashboard-nav-item': true,
			'rounded-lg overflow-hidden mb-2': true
		};
	}

	get DashboardNavItems(): MobileNavItem[] {
		const compare: MobileNavItem = {
			iconComponent: '',
			text: 'Compare',
			route: {
				name: Routes.AthleteComparativeDataCompare
			},
			'v-icon': '$spiderchart'
		};
		const myprogress: MobileNavItem = {
			iconComponent: '',
			text: 'My Progress',
			route: {
				name: Routes.AthleteComparativeDataMyProgress
			},
			'v-icon': '$linegraph'
		};
		const performance: MobileNavItem = {
			iconComponent: '',
			text: 'Performance',
			route: {
				name: Routes.AthleteComparativeDataPerformanceSummary,
				params:{
					metric: AthleteMetricValues[0],
				}
			},
			'v-icon': '$performance'
		};
		return [compare, myprogress, performance];
	}

	get RouterViewProps(): {props:Record<string, any>,on:Record<string, any>} {
		return {
			props:{
				useSampleData: this.useSampleData,
			},
			on:{},
		};
	}
}
