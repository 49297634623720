import { render, staticRenderFns } from "./MetricPercentileCard.vue?vue&type=template&id=95130ade&scoped=true&"
import script from "./MetricPercentileCard.vue?vue&type=script&lang=ts&"
export * from "./MetricPercentileCard.vue?vue&type=script&lang=ts&"
import style0 from "./MetricPercentileCard.vue?vue&type=style&index=0&id=95130ade&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95130ade",
  null
  
)

export default component.exports