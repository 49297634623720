
import { Component, Mixins } from 'vue-property-decorator';
import { AthleteMetric } from '@/../types/enums';
import { AthleteMetricsMixin, VuetifyMixin, FormRulesMixin } from '../../../mixins';
import { title, toFixed } from '../../../pipes';
import AttemptField from '../utility/AttemptField.vue';
import { SelfAssessmentTestAttemptModel, SelfAssessmentTestModel } from '../../../models/athlete/SelfAssessmentTestModel';

@Component({
	components: { AttemptField },
})
export default class SelfAssessmentPowerTest extends Mixins(AthleteMetricsMixin, FormRulesMixin, VuetifyMixin){
	title = title;
	public readonly Title: string = this.title(this.metric);
	get metric(): AthleteMetric{
		return AthleteMetric.Power;
	}

	getAttemptResult(index: number): number{
		const attempt = this.attempts[index];
		if(attempt === undefined) return 0;
		console.log(`getAttemptResult(${index})`, +attempt.result)
		return +attempt.result;
	}

	reachHeight: number = null;
	get rulesMinHeight(): Array<(v: number) => string | boolean>{
		return [
			(v: any): string | boolean => !!v || 'Required',
			(v: number): boolean | string =>  (+v > this.reachHeight) || 'Jump height must be greater than reach height'
		];
	}
	get rulesNotRequired(): (v:number) => string | boolean {
		return (v: number): boolean | string =>  (+v > this.reachHeight) || 'Jump height must be greater than reach height'
	}

	formValid: boolean = false;
	get TestValid(): boolean{
		return this.formValid && this.attempts.length >= 1;
	}
	attempts: SelfAssessmentTestAttemptModel[] = new Array(3).fill(null).map(() => {
		return new SelfAssessmentTestAttemptModel();
	});
	
	clearAttempt(index: number){
		this.attempts.splice(index, 1);
	}

	computeJumpHeight(height: number): number{
		return toFixed(height - this.reachHeight);
	}

	finish(){
		const computedAttempts = this.attempts.map(attempt => {
			const height = this.computeJumpHeight(+attempt.result);
			return new SelfAssessmentTestAttemptModel().load({
				...attempt,
				result: height,
				label: height,
			});
		});
		const [ finalResult ] = computedAttempts.sort((a,b) => b.result - a.result);
		this.$emit('finish', new SelfAssessmentTestModel(this.metric).load({
			finalResult: finalResult.result,
			finalResultLabel: finalResult.label,
			attempts: this.attempts
		}));
	}
}
