
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, BAIconsMixin } from "@/mixins";
import * as Routes from "@/../types/constants/web_client_user.routes";

import RecruitingProfileCard from "@/components/profile/recruiting/RecruitingProfileCard.vue";
import RecruitingProfileInfoProvider from "@/components/hoc/RecruitingProfileInfoProvider.vue";
import VideoLibraryProvider from "@/components/hoc/VideoLibraryProvider.vue";
import { VideoClipModel } from '@/models/video/VideoClipModel';
import ClipPlayerStudio from '@/components/videoClipPlayer/ClipPlayerStudio.vue';
import AthleteVideoHighlightReel from '@/components/profile/athlete/AthleteVideoHighlightReel.vue';
import VideoClipLibrary from '@/components/ui/videos/VideoClipLibrary.vue';

@Component({
	components: {
		RecruitingProfileInfoProvider,
		ClipPlayerStudio,
		AthleteVideoHighlightReel,
		VideoClipLibrary,
		Page,
		RecruitingProfileCard,
		VideoLibraryProvider,
	},
})
export default class AthleteRecruitingProfileVideosView extends Mixins(VuetifyMixin, BAIconsMixin) {
	@Prop({ type: String, default: null }) athleteId: string | null;
	@Prop({ default: null }) sharingUrlId: string | null;

	selectedVideoClip: VideoClipModel | null = null;
	selectedHighlightClip: VideoClipModel | null = null;
	videoStudioVisible: boolean = false;
	clipPlayerVisible: boolean = false;

	highlightReelPlayerVisible: boolean = false;
	HighlightReelClips(VideoClips: VideoClipModel[]): VideoClipModel[]{
		return VideoClips.filter(clip => clip.inHighlightReel);
	}

	filteredClips: VideoClipModel[] = [];
	updateFilteredClips(clips: VideoClipModel[]): void{
		this.filteredClips = clips;
	}

	$refs: {
		highlightClipPlayer: ClipPlayerStudio,
		videoClipPlayer: ClipPlayerStudio,
	};
	playAll(): void{
		this.highlightReelPlayerVisible = true;
		this.$refs.highlightClipPlayer.playAll();
	}
	playClip(videoClip: VideoClipModel): void{
		this.clipPlayerVisible = true;
		this.$nextTick(() => {
			this.selectedVideoClip = videoClip;
			this.$refs.videoClipPlayer.playClip(videoClip);
		});
	}
	videoClipClicked(videoClip: VideoClipModel): void{
		this.playClip(videoClip);
	}
	playHighlightClip(videoClip: VideoClipModel): void{
		this.highlightReelPlayerVisible = true;
		this.$nextTick(() => {
			this.selectedHighlightClip = videoClip;
			this.$refs.highlightClipPlayer.playClip(videoClip);
		});
	}
	highlightVideoClipClicked(videoClip: VideoClipModel): void{
		this.playHighlightClip(videoClip);
	}

	backToProfile(): void{
		this.$router.push({
			name: Routes.CoachViewAthlete,
			params:{
				...this.$route.params,
				sharingUrlId: this.sharingUrlId
			}
		});
	}

	goToDashboard(): void {
		this.$router.push({
			name: Routes.CoachDashboard
		})
	}
}
