
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { Route } from 'vue-router';

export type MobileNavItem = {
	route: Partial<Route>;
	text: string,
	'v-icon': string | null,
	iconComponent: string | null,
}

@Component
export default class MobileNav extends Mixins(VuetifyMixin, BAIconsMixin) {
	open: boolean = false;
	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: [] }) navItems: MobileNavItem[];

	get ActiveRouteNavItem(): MobileNavItem {
		return this.navItems.find(item => item.route.name === this.$route.name);
	}
	get ActiveRouteText(): string {
		const route = this.navItems.find(
			item => item.route.name === this.$route.name
		);
		if (route === undefined) return '';
		return route.text;
	}
}
