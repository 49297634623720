
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AthleteMetric } from '@/../types/enums';

@Component
export default class AthleteSelfAssessmentMetricDescription extends Vue{
	@Prop({ required: true }) metric: AthleteMetric;

	get Acceleration(): boolean{
		return this.metric === AthleteMetric.Acceleration;
	}
	get Speed(): boolean{
		return this.metric === AthleteMetric.Speed;
	}
	get Power(): boolean{
		return this.metric === AthleteMetric.Power;
	}
	get Agility(): boolean{
		return this.metric === AthleteMetric.Agility;
	}
	get Recovery(): boolean{
		return this.metric === AthleteMetric.Recovery;
	}
	
}
