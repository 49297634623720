
import { Component, Mixins } from 'vue-property-decorator';
import { MyAthleteMixin } from '@/mixins';
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";

@Component({
	components:{
		AthleteScoutingReportsCard,
	}
})
export default class AthleteScoutingReportsView extends Mixins(MyAthleteMixin){
	mounted() {
		this.loadReports();
	}

	async loadReports() {
		await this.loadMyAthleteProfiles();
		await this.MyAthleteProfile.loadTeams();
	}
}
