
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AppHostnameMixin, MyAthleteMixin, FormRulesMixin, BAIconsMixin } from "@/mixins";
import { formatPrice, title } from "@/pipes";
import { mdiTwitter, mdiFacebook, mdiWeb, mdiInstagram, mdiLinkedin, mdiEmail } from "@mdi/js";
import { SchoolModel, LocationModel, SportDetailModel, CoachDetailModel } from "@/models/school/SchoolModel";
import { Currency } from "@/../types/enums/currency";
import { SportName, SocialMedia, SportGender, Gender, Country } from '@/../types/enums';
import { ConferenceLogoMapping } from '@/../types/constants/conferences';
import ShareProfile from "../../profile/recruiting/ShareProfile.vue";
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";

@Component({
	components: { ShareProfile, SharingInfoProvider }
})
export default class SpecificSchool extends Mixins(VuetifyMixin, AppHostnameMixin, MyAthleteMixin, FormRulesMixin, BAIconsMixin) {
	@Prop() school: SchoolModel;
	@Prop({ type: Number, default: null }) locationIndex: number | null;
	@Prop({ required: true }) favorites: string[];
	@Prop({ type: String, default: null }) gender: Gender | null;
	@Prop({ type: Boolean }) blur: boolean;

	shareProfileClick: boolean = false;
	Country = Country
	mdiTwitter = mdiTwitter;
	mdiFacebook = mdiFacebook;
	mdiInstagram = mdiInstagram;
	mdiLinkedin = mdiLinkedin;
	mdiEmail = mdiEmail;

	mdiWeb = mdiWeb;
	formatPrice = formatPrice;
	titleCase = title;

	get SelectedSport(): SportName{
		if( !this.athleteProfile ) return SportName.Soccer;
		return this.athleteProfile.Sport.name;
	}

	SocialMediaColorAndIcon = {	
		[SocialMedia.Facebook]: {
			icon: mdiFacebook
		},
		[SocialMedia.Twitter]: {
			icon: mdiTwitter
		},
		[SocialMedia.Website]: {
			icon: mdiWeb
		},
		[SocialMedia.Instagram]: {
			icon: mdiInstagram,
		},
		[SocialMedia.LinkedIn]: {
			icon: mdiLinkedin,
		},
		[SocialMedia.TikTok]: {
			icon: mdiWeb
		}
	}

	get SelectedSportGender(): SportGender {
		switch (this.gender) {
		case Gender.Male:
			return SportGender.MEN;
		case Gender.Female:
			return SportGender.WOMEN;
		case Gender.Coed:
			return SportGender.COED
		default:
			return SportGender.MEN
		}
	}
	get CoachesWithEmails(): string[]{
		if( !this.Coaches ) return [];
		return this.Coaches.map(x => x.email)
	}
	findCoach(email: string): string{
		const coach = this.Coaches.find(x => x.email === email);
		return coach.name + ' | ' + coach.title
	}
	formatNumber(value: number): string {
		return value
			.toString()
			.replace(/\D/g, "")
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	get School(): SchoolModel {
		return this.school;	
	}

	get LocationDetail(): LocationModel {
		return this.school.locations[this.locationIndex === null || this.locationIndex === undefined ? 0 : this.locationIndex];
	}

	get Sport(): SportDetailModel | null {
		const sport = this.LocationDetail.sports.find(sport => sport.name.toLowerCase() === this.SelectedSport.toLowerCase() && sport.gender.toLowerCase() === this.SelectedSportGender.toLowerCase());

		return sport === undefined ? null : sport;
	}
	get SportName(): string {
		if( !this.Sport ) return undefined;
		return this.Sport.name;
	}

	get Coaches(): CoachDetailModel[] | null {
		if (this.Sport === null) {
			return null
		}
		else {
			return this.Sport.coaches;
		}
	}

	onShareProfile() {}

	get Blur(): boolean {
		return this.blur;
	}

	get Gender(): string {
		if (this.SelectedSportGender == SportGender.WOMEN) {
			return "Women's";
		}
		else if (this.SelectedSportGender == SportGender.MEN) {
			return "Men's"
		}
		else {
			return "Coed";
		}
	}

	get Currency(): Currency {
		return Currency.USD;
	}

	get IsFavorite(): boolean {
		return this.favorites !== null ? this.favorites.indexOf(this.School.id)  > -1 : false;
	}

	get OnCampusHousing(): string {
		if (this.school.onCampusHousingAvailable === true) {
			return "Yes";
		} else {
			return "No";
		}
	}

	get UndergradEnrollment(): string {
		return this.formatNumber(this.school.undergradsEnrolled);
	}

	get FreshmanEnrollment(): string {
		return this.formatNumber(this.school.freshmanEnrolled);
	}

	get AvatarSize(): { size: number; borderSize: number } {
		if (this.IsMobile) {
			return {
				size: 165,
				borderSize: 7
			};
		}
		return {
			size: 225,
			borderSize: 7
		};
	}
	get ConferenceSize(): { size: number; borderSize: number } {
		if (this.IsMobile) {
			return {
				size: 75,
				borderSize: 5
			};
		}
		return {
			size: 100,
			borderSize: 5
		};
	}
	get ConferenceImage(): string {
		return ConferenceLogoMapping[this.LocationDetail.Conference];
	}
	get ConferenceLogoURL(): string {
		return `https://${this.CDNBaseUrl}/conference-logos/${this.ConferenceImage}`
	}

	get ConferenceText(): string { 
		return this.Sport !== null && this.Sport.conference !== null ? this.Sport.conference : '--'
	}

	get DivisionText(): string {
		return this.Sport !== null && this.Sport.division !== null ? this.Sport.division : '--'
	}

	maleToFemaleRatio: Array<string> = [];

	get Ratio(): string {
		// console.log(this.school.maleToFemaleUndergradRatio)
		const number = 1.2;

		this.maleToFemaleRatio[0] = ((number / (number + 1)) * 100).toFixed(0);
		this.maleToFemaleRatio[1] = (100 - (number / (number + 1)) * 100).toFixed(0);

		return `${this.maleToFemaleRatio[0]}% male / ${this.maleToFemaleRatio[1]}% female`;
	}

	clickFavourite(): void {
		this.$emit("clicked:favourite", this.school.id);
	}
}
