
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, MyAthleteMixin, AthleteRoutingMixin } from '@/mixins';
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";
import SharingMetric from "@/components/sharing/SharingMetric.vue";
import ShareProfile from '@/components/profile/recruiting/ShareProfile.vue';

@Component({
	components: {
        SharingInfoProvider,
        SharingMetric,
        ShareProfile,
    }
})
export default class SharingSummaryCard extends Mixins(VuetifyMixin, MyAthleteMixin, AthleteRoutingMixin) {
	showShareProfile: boolean = false;
}

