
import { Component, Mixins } from 'vue-property-decorator';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import TOSCheckboxSelfAssessment from '../forms/TOSCheckboxSelfAssessment.vue';
import EmbeddedYoutubeVideo from '../ui/EmbeddedYoutubeVideo.vue';
import { youtubeUrl } from '@/helpers/youtube'

@Component({
	components: {
		TOSCheckboxSelfAssessment,
		EmbeddedYoutubeVideo,
	}
})
export default class AthleteSelfAssessmentIntro extends Mixins(VuetifyMixin, BAIconsMixin){
	youtubeUrl = youtubeUrl;
	releaseAgreed = false;
	beginTest(): void{
		this.$router.push({
			name: Routes.AthleteSelfAssessmentForm,
			params:{
				...this.$route.params,
			}
		});
	}
}
