
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MyAthleteMixin } from '@/mixins';
import AthleteScheduleCard from "@/components/profile/athlete/AthleteScheduleCard.vue";

@Component({
	components:{
		AthleteScheduleCard
	}
})
export default class AthleteScheduleView extends Mixins(MyAthleteMixin){
	@Prop({required: true}) athleteId: string;
}
