
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetricsMixin, FormRulesMixin, VuetifyMixin } from '../../mixins';
import { title, getMetricIcon } from '../../pipes';
import { AthleteMetric } from '@/../types/enums';
import * as Routes from '@/../types/constants/web_client_user.routes';
import TestGroupHeader from './TestGroupHeader.vue';
import AthleteSelfAssessmentFormStep from './AthleteSelfAssessmentFormStep.vue';
import WarmupSvg from '../svg/WarmupSvg.vue';
import EmbeddedYoutubeVideo from '../ui/EmbeddedYoutubeVideo.vue';
import SelfAssessmentAccelerationTest from './metrics/SelfAssessmentAccelerationTest.vue';
import SelfAssessmentPowerTest from './metrics/SelfAssessmentPowerTest.vue';
import SelfAssessmentSpeedTest from './metrics/SelfAssessmentSpeedTest.vue';
import SelfAssessmentAgilityTest from './metrics/SelfAssessmentAgilityTest.vue';
import SelfAssessmentRecoveryTest from './metrics/SelfAssessmentRecoveryTest.vue';
import { athleteProfile, userStore } from '../../store';
import { SelfAssessmentModel } from '../../models/athlete/SelfAssessmentModel';
import { SelfAssessmentTestModel } from '../../models/athlete/SelfAssessmentTestModel';
import { formatDatePretty } from '../../helpers';
import { youtubeUrl } from '@/helpers/youtube';

@Component({
	components: {
		AthleteSelfAssessmentFormStep,
		SelfAssessmentAccelerationTest,
		SelfAssessmentSpeedTest,
		SelfAssessmentPowerTest,
		SelfAssessmentAgilityTest,
		SelfAssessmentRecoveryTest,
		TestGroupHeader,
		WarmupSvg,
		EmbeddedYoutubeVideo,
	},
})
export default class AthleteSelfAssessmentForm extends Mixins(AthleteMetricsMixin, FormRulesMixin, VuetifyMixin){
	title = title;
	getMetricIcon = getMetricIcon;
	youtubeUrl = youtubeUrl;
	@Prop({ default: null }) selfAssessmentId: string | null;
	@Prop() metric: AthleteMetric;

	get Loading(): boolean{
		return athleteProfile.activeSelfAssessmentLoading;
	}
	get ActiveSelfAssessment(): SelfAssessmentModel | null{
		return athleteProfile.activeSelfAssessment;
	}

	async created(): Promise<void>{
		const metric = this.$route.params.metric;
		const index = this.AllAthleteMetrics.indexOf(metric as AthleteMetric);
		if(index > -1){
			this.step = (index + 1);
		}
		await athleteProfile.createOrLoadActiveSelfAssessment({ athleteId: userStore.athleteId });
		this.loadSelfAssessmentIntoForm(this.ActiveSelfAssessment);
	}

	updateResults(metric: AthleteMetric, results: SelfAssessmentTestModel){
		this.formAssessment[this.formAssessment.resultsKey(metric)] = results;
	}

	finishTest(metric: AthleteMetric, result: SelfAssessmentTestModel){
		this.formAssessment[this.formAssessment.resultsKey(metric)] = result;
		this.patchSelfAssessment();
	}

	step = 0;
	updateStep(step: number){
		this.step = step;
		this.stepToRoute(step);
		
	}
	private stepToRoute(step: number){
		if(step === undefined || step <= 0){
			return this.$router.push({
				params:{
					metric: undefined,
				}
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			}).catch(() => {});
		}
		const metric = this.AllAthleteMetrics[step - 1];
		if(metric === undefined) return;
		this.$router.push({
			params:{
				metric,
			}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		}).catch(() => {});
	}
	warmupNext(): void{
		this.warmupCompleted = true;
		this.nextStep();
	}
	nextStep(): void{
		this.step++;
	}
	saveAndContinue(): void{
		this.patchSelfAssessment();
		this.nextStep();
	}

	metricComplete(metric: AthleteMetric): boolean{
		return this.formAssessment.results(metric).finalResult !== null;
	}

	getMetricTestComponent(value: AthleteMetric): string{
		return `SelfAssessment${this.title(value)}Test`;
	}

	loadSelfAssessmentIntoForm(assessment: SelfAssessmentModel): void{
		this.formAssessment = new SelfAssessmentModel().load(assessment);
	}
	async patchSelfAssessment(): Promise<void>{
		await athleteProfile.patchSelfAssessment({ patch: this.formAssessment });
		this.loadSelfAssessmentIntoForm(athleteProfile.activeSelfAssessment);
	}

	measurementsValid: boolean = false
	get MeasurementsComplete(): boolean{
		return this.formAssessment.standingHeightCm !== null && this.formAssessment.sittingHeightWithBoxCm !== null && this.formAssessment.bodyMassKg !== null;
	}
	warmupCompleted: boolean = false
	get WarmupCompleted(): boolean{
		return this.warmupCompleted || (this.ActiveSelfAssessment && this.ActiveSelfAssessment.IsComplete);
	}
	stepValid: boolean[] = [];
	formAssessment = new SelfAssessmentModel();

	saveAndClose(){
		this.patchSelfAssessment();
		this.$router.push({
			name: Routes.AthleteSelfReportSummary,
			params:{
				...this.$route.params,
			}
		});
	}

	get TodaysDate(): string{
		return `${formatDatePretty(new Date())}`;
	}

}
