
import { Component, Mixins } from 'vue-property-decorator';
import { AthleteMetric } from '@/../types/enums';
import { AthleteMetricsMixin, VuetifyMixin } from '../../../mixins';
import { title, toFixed } from '../../../pipes';
import { youtubeUrl } from '@/helpers/youtube';
import AttemptField from '../utility/AttemptField.vue';
import Stopwatch from '../utility/Stopwatch.vue';
import EmbeddedYoutubeVideo from '../../ui/EmbeddedYoutubeVideo.vue';
import { SelfAssessmentTestAttemptModel, SelfAssessmentTestModel } from '../../../models/athlete/SelfAssessmentTestModel';

@Component({
	components: { AttemptField, EmbeddedYoutubeVideo, Stopwatch },
})
export default class SelfAssessmentRecoveryTest extends Mixins(AthleteMetricsMixin, VuetifyMixin){
	title = title;
	youtubeUrl = youtubeUrl;
	public readonly Title: string = this.title(this.metric);
	get metric(): AthleteMetric{
		return AthleteMetric.Recovery;
	}

	get TestValid(): boolean{
		return this.levelAchieved > 0;
	}
	attempts: SelfAssessmentTestAttemptModel[] = [];
	
	levelAchieved: number = null;
	clearAttempt(){
		this.levelAchieved = null;
	}

	finish(){
		this.attempts.push(new SelfAssessmentTestAttemptModel().load({
			result: this.levelAchieved,
			label: `${this.levelAchieved}`,
		}));
		const [ finalResult ] = this.attempts.slice().sort((a,b) => a.result - b.result);
		this.$emit('finish', new SelfAssessmentTestModel(this.metric).load({
			finalResult: toFixed(finalResult.result),
			finalResultLabel: finalResult.label,
			attempts: this.attempts
		}));
	}
}
