
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../../mixins';
import { AgeGroup, AgeGroupValues, UnderU13AgeGroupValues, Under13AgeGroup } from '@best-athletes/ba-types';
import { ContextMenuItem } from '@/../types/interfaces';
@Component
export default class CompareAgeGroupSelector extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({type: Boolean, default: false}) loading: boolean;
	
	@Prop({ default: 2 }) compareCount: number;
	@Prop({ required: true }) myAgeGroup: AgeGroup;
	@Prop({ default: () => ([]) }) value: AgeGroup[];
	@Prop({ default: true }) disabled: boolean; 

	input(value: AgeGroup[]): void{
		this.$emit('input', value);
	}

	selectGroup(index: number, { item }: { item: ContextMenuItem<AgeGroup> }): void{
		if(item.value === undefined) return;
		const newValue = this.value.slice();
		newValue.splice(index, 1, item.value);
		this.input(newValue);
	}
	removeGroup($event: Event, index: number): void{
		$event.stopPropagation();
		this.value.splice(index, 1, undefined);
		this.input(this.value);
	}

	AgeGroupDisplay(age: AgeGroup): string {
		if( age == AgeGroup.O20 ) return "21+";
		return age.toString();
	}
	get MyAgeGroupDisplay(): string {
		return this.AgeGroupDisplay(this.MyAgeGroup);
	}
	get MyAgeGroup(): AgeGroup{
		return this.myAgeGroup;
	}
	get CompareGroups(): AgeGroup[]{
		return this.value;
	}

	get MyGroupIsUnderU13(): boolean{
		return UnderU13AgeGroupValues.includes(this.myAgeGroup as unknown as Under13AgeGroup);
	}

	get CompareItems(): ContextMenuItem<AgeGroup>[]{
		return AgeGroupValues
			.filter(ageGroup => {
				if(this.MyGroupIsUnderU13 && ageGroup === AgeGroup.U13) return false;
				return ageGroup !== this.MyAgeGroup && this.value.indexOf(ageGroup) === -1;
			})
			.map(ageGroup => ({
				text: this.AgeGroupDisplay(ageGroup),
				value: ageGroup,
			}));
	}
}
