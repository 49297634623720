
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, MyAthleteMixin, StringsMixin, UpgradeNowMixin, AthleteRoutingMixin, BAIconsMixin } from "@/mixins";
import { baSchoolStore } from "@/store";
import { BASchoolModel, BASportDetailModel } from "@/models/baSchool";
import { SportModel } from '../models/sport/SportModel';
import { genderTextPlural } from '@/pipes';
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";
import ShareProfile from "@/components/profile/recruiting/ShareProfile.vue";
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import * as Routes from "@/../types/constants/web_client_user.routes";
import { Route } from "vue-router";
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";

@Component({
	components: {
		ShareProfile,
		SharingInfoProvider,
		RecruitingProfileInfo,
		SubscriptionInfoProvider
	}
})
export default class BASchoolDetailPage extends Mixins(VuetifyMixin, MyAthleteMixin, StringsMixin, UpgradeNowMixin, AthleteRoutingMixin, BAIconsMixin) {
	@Prop({ type: String, default: null }) schoolId: string | null;
	sharingProfile: boolean = false;

	school: BASchoolModel = new BASchoolModel;
	mounted() {
		this.loadSchool();
	}
	async loadSchool() {
		this.school = await baSchoolStore.findBASchool(this.schoolId)
	}
	get Loading(): boolean{
		return baSchoolStore.findingBASchool || this.IsAthleteLoading;
	}

	gotoURL(url: string) {
		window.open(url, '_blank');
	}

	get Sport(): BASportDetailModel {
		if( !this.school.HasSports ) return undefined;
		return this.school.Sport(this.TheAthleteProfile.Sport.name, this.TheAthleteProfile.gender);
	}
	get HasSport(): boolean {
		return !!this.Sport;
	}
	get AthleteSport(): SportModel {
		return this.TheAthleteProfile.Sport;
	}
	get SportName(): string {
    	return `${genderTextPlural(this.TheAthleteProfile.gender, {adult: true})} ${this.AthleteSport.name}`;
	}

	get AthleteSchoolRoute(): Partial<Route> {
		return {
			name: Routes.AthleteSchoolSearch,
			params: {
				...this.$route.params,
				athleteId: this.TheAthleteId
			}
		};
	}
}
