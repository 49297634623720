
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteRoutingMixin, MyAthleteMixin, StringsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';

@Component
export default class AthleteEventsCard extends Mixins(MyAthleteMixin, AthleteRoutingMixin, StringsMixin) {
    @Prop({type: Boolean}) flat;
    @Prop({type: Boolean}) buttons;
    
    PastEventsHeader: DataTableHeader<any>[] = [
        { text:'Name', value:'name', sortable: false },
        { text:'Date', value:'DateFormatted', sortable: true },
        { text:'Location', value:'LocationName', sortable: false },
        { text:'', value:'AthleteTicket', sortable: false }
    ]
}

